import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from './Layout';
import {LeadTokenContextProvider} from '@/contexts/LeadTokenContext';
import Confirmation from '@/pages/Confirmation';
import DeadEndOutOfTerritory from '@/pages/DeadEndOutOfTerritory';
import Landing from '@/pages/Landing';
import NotFound from '@/pages/NotFound';
import Privacy from '@/pages/Privacy';

const App = () : JSX.Element => {
    return (
        <BrowserRouter>
            <LeadTokenContextProvider>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Landing/>}/>
                        <Route path=":leadTokenId?" element={<Landing/>}/>
                        <Route path="out-of-territory" element={<DeadEndOutOfTerritory/>}/>
                        <Route path="confirmation" element={<Confirmation/>}/>
                        <Route path="privacy" element={<Privacy/>}/>
                        <Route path="not-found" element={<NotFound/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
            </LeadTokenContextProvider>
        </BrowserRouter>
    );
};

export default App;
