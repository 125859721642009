import {zodResolver} from '@hookform/resolvers/zod';
import {useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {RhfCheckboxGroup, RhfRadioGroup} from 'mui-rhf-integration';
import type {Dispatch, SetStateAction} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import QuizNavigation from '@/components/QuizNavigation';
import {usePostQuizAnswersMutation} from '@/mutations/lead-token';
import type {LeadToken} from '@/types/lead-token';
import type {QuizStepType} from '@/types/quiz';
import {errorMap} from '@/utils/zod';

type Props = {
    leadToken : LeadToken;
    step : QuizStepType;
    setCurrentStepIndex : Dispatch<SetStateAction<number>>;
    setBackOverride : Dispatch<SetStateAction<boolean>>;
};

const QuizStep = ({leadToken, step, setCurrentStepIndex, setBackOverride} : Props) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const quizAnswersMutation = usePostQuizAnswersMutation(leadToken);

    const schema = z.object({
        [step.stepKey]: step.multiselect ? z.string().array() : z.string(),
    });

    type FormValues = z.infer<typeof schema>;

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: {
            [step.stepKey]: leadToken.quizAnswers[step.stepKey],
        },
    });

    const handleSubmit = async (values : FormValues) => {
        await quizAnswersMutation.mutateAsync(values, {
            onSuccess: () => {
                setCurrentStepIndex(step.stepNumber + 1);
            },
        });
    };

    const sharedInputStyles = {
        display: 'flex',
        alignItems: 'center',
        mt: 2,
        'label.MuiFormControlLabel-labelPlacementEnd': {
            border: '1px solid',
            justifyContent: 'center',
            mx: '10px',
            marginBottom: '10px',
            width: '200px',
            height: 20,
            backgroundColor: '#f7f6f6',
            padding: 3,
        },
        '.MuiFormGroup-root': {
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'center',
        },
        '.MuiTypography-root': {
            fontSize: '18px',
            lineHeight: 1,
        },
    };

    return <Grid container xs={12} component={'form'} name={`quiz_step_${step.stepNumber}`}
        onSubmit={form.handleSubmit(handleSubmit)}
        sx={{
            mt: 2,
            textAlign: 'center',
            alignItems: 'center',
        }}>
        <Grid xs={12} sx={{px: isMobile ? 0 : 6}}>
            <Typography variant={'h4'} sx={{fontWeight: 400, fontSize: isMobile ? '1.5rem' : '2.125rem'}}>
                {step.questionText}
            </Typography>
            {step.multiselect && <Typography variant={'body1'} sx={{fontWeight: 400}}>
                (select all that apply)
            </Typography>}
        </Grid>
        <Grid xs={12}>
            {step.multiselect
                ? <RhfCheckboxGroup
                    control={form.control}
                    name={step.stepKey}
                    label={''}
                    sx={{
                        ...sharedInputStyles,
                        'span.MuiCheckbox-root': {
                            display: 'none',
                        },
                        'label:has(input[type="checkbox"]:checked)': {
                            backgroundColor: '#6CC14C',
                        },
                    }}
                    options={step.options.map(option => ({
                        value: option.value,
                        label: option.displayText,
                    }))}
                />
                : <RhfRadioGroup
                    control={form.control}
                    name={step.stepKey}
                    label={''}
                    sx={{
                        ...sharedInputStyles,
                        'span.MuiRadio-root': {
                            display: 'none',
                        },
                        'label:has(input[type="radio"]:checked)': {
                            backgroundColor: '#6CC14C',
                        },
                    }}
                    options={step.options.map(option => ({
                        value: option.value,
                        label: option.displayText,
                    }))}
                />}
        </Grid>
        <QuizNavigation
            buttonText={'Next Question'}
            disabled={!form.formState.isValid}
            stepNumber={step.stepNumber}
            setCurrentStepIndex={setCurrentStepIndex}
            setBackOverride={setBackOverride}
        />
    </Grid>;
};

export default QuizStep;
