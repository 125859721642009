import type {StyledComponentProps} from '@mui/material';
import {Box, useTheme} from '@mui/material';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';

const StyledGrid = styled(Grid)(() => ({
    border: '1px solid #fff',
    borderRadius: 15,
    backgroundColor: '#fbfaf7',
    margin: 15,
}));

const StyledWhyRbaBox = styled(Box)(({sx} : {sx ?: StyledComponentProps}) => ({
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    minHeight: 228,
    ...sx,
}));

const StyledStepNumber = styled(Typography)(() => ({
    width: '50px',
    height: '50px',
    backgroundColor: '#6CC14C',
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '50px',
    borderRadius: '50%',
    display: 'inline-block',
}));

const WhyRbaSection = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));

    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'why_rba'});
        }
    }, [isSectionVisible]);

    return <Grid container spacing={3} xs={12} sx={{
        justifyContent: 'center', textAlign: 'center', margin: 'auto', my: mobile ? 2 : 5,
    }}>
        <Grid xs={12} md={12} mdOffset={1}>
            <Typography variant={'h3'} ref={ref} sx={{
                fontSize: {
                    xs: "clamp(1.5rem, 2.5vw, 1.5rem)",
                    sm: "clamp(1.75rem, 2.5vw, 1.75rem)",
                    md: "clamp(2.25rem, 2.5vw, 2.25rem)",
                    lg: "clamp(3rem, 2.5vw, 3rem)",
                },
            }}>Why Customers Love Renewal by Andersen</Typography>
            <Typography variant={'body1'} sx={{px: mobile ? 2 : 20, py: 2}}>
                Our <b>Signature Service Promise</b> is committed to giving you the best customer experience possible,
                including:
            </Typography>
        </Grid>
        <StyledGrid xs={12} md={5} mdOffset={1}>
            <StyledWhyRbaBox>
                <Box sx={{px: 5, py: 0}}>
                    <StyledStepNumber>1</StyledStepNumber>
                </Box>
                <Box sx={{p: 0}}>
                    <Typography variant={'h5'} sx={{mb: 2}}>Energy Efficiency</Typography>
                    <Typography variant={'body1'}>
                        Renewal by Andersen has energy efficient glass options that can make them ENERGY STAR® certified
                        and can help reduce heating and cooling costs.<sup>2</sup>
                    </Typography>
                </Box>
            </StyledWhyRbaBox>
        </StyledGrid>
        <StyledGrid xs={12} md={5}>
            <StyledWhyRbaBox>
                <Box sx={{px: 5, py: 0}}>
                    <StyledStepNumber>2</StyledStepNumber>
                </Box>
                <Box sx={{p: 0}}>
                    <Typography variant={'h5'} sx={{mb: 2}}>One-Stop Shop</Typography>
                    <Typography variant={'body1'}>
                        Renewal by Andersen eliminates the need to work with multiple companies. We are responsible for
                        all aspects of your window and door replacement and installation.
                    </Typography>
                </Box>
            </StyledWhyRbaBox>
        </StyledGrid>
        <StyledGrid xs={12} md={5} mdOffset={1}>
            <StyledWhyRbaBox>
                <Box sx={{px: 5, py: 0}}>
                    <StyledStepNumber>3</StyledStepNumber>
                </Box>
                <Box sx={{p: 0}}>
                    <Typography variant={'h5'} sx={{mb: 2}}>Long Term Value</Typography>
                    <Typography variant={'body1'}>
                        Our comprehensive, hands-on approach provides you with window and door installation quality,
                        beauty, and energy savings.
                    </Typography>
                </Box>
            </StyledWhyRbaBox>
        </StyledGrid>
        <StyledGrid xs={12} md={5}>
            <StyledWhyRbaBox>
                <Box sx={{px: 5, py: 2}}>
                    <StyledStepNumber>4</StyledStepNumber>
                </Box>
                <Box sx={{p: 0}}>
                    <Typography variant={'h5'} sx={{mb: 2}}>Unrivaled Beauty</Typography>
                    <Typography variant={'body1'}>
                        Renewal by Andersen offers stunning door and window styles, all available in a wide variety
                        of interior and exterior colors.
                    </Typography>
                </Box>
            </StyledWhyRbaBox>
        </StyledGrid>
    </Grid>;
};

export default WhyRbaSection;
