import {Divider, LinearProgress} from '@mui/material';
import styled from '@mui/material/styles/styled';
import Grid from '@mui/material/Unstable_Grid2';
import {useEffect, useState} from 'react';
import DiscoverBeauty from '@/components/DiscoverBeauty';
import FibrexBenefitsSection from '@/components/FibrexBenefitsSection';
import FrameColors from '@/components/FrameColors';
import HeroSection from '@/components/HeroSection';
import OurProcess from '@/components/OurProcess';
import Quiz from '@/components/Quiz';
import Testimonials from '@/components/Testimonials';
import WhyRbaSection from '@/components/WhyRbaSection';
import WindowDoorOptions from '@/components/WindowDoorOptions';
import WindowMaterials from '@/components/WindowMaterials';
import {useLeadTokenContext} from '@/contexts/LeadTokenContext';
import type {LeadToken} from '@/types/lead-token';
import {quizJson} from '@/types/quiz';

const StyledDivider = styled(Divider)(() => ({
    width: '100%',
}));

const LandingDisplay = ({leadToken} : {leadToken : LeadToken}) : JSX.Element => {
    const [currentStepIndex, setCurrentStepIndex] = useState(1);
    const [backOverride, setBackOverride] = useState(false);
    const currentQuizStep = quizJson.steps.find(step => step.stepNumber === currentStepIndex) ?? quizJson.steps[0];

    useEffect(() => {
        if (backOverride) {
            return;
        }

        if (currentStepIndex < 4 && leadToken.quizAnswers[currentQuizStep.stepKey]) {
            setCurrentStepIndex(currentQuizStep.stepNumber + 1);
        }

        if (leadToken.zip) {
            setCurrentStepIndex(5);
        }
    }, [currentStepIndex]);

    return (
        <Grid container>
            <HeroSection leadToken={leadToken}/>
            <StyledDivider/>
            <Grid container xs={12} id={'quiz-container'}>
                <Quiz
                    leadToken={leadToken}
                    currentStep={currentQuizStep}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                    setBackOverride={setBackOverride}
                />
            </Grid>
            <StyledDivider/>
            <FibrexBenefitsSection leadToken={leadToken}/>
            <StyledDivider/>
            <Grid container xs={12} sx={{justifyContent: 'center'}}>
                <WindowMaterials leadToken={leadToken}/>
            </Grid>
            <StyledDivider/>
            <DiscoverBeauty leadToken={leadToken}/>
            <StyledDivider/>
            <Testimonials leadToken={leadToken}/>
            <StyledDivider/>
            <OurProcess leadToken={leadToken}/>
            <StyledDivider/>
            <WhyRbaSection leadToken={leadToken}/>
            <StyledDivider/>
            <WindowDoorOptions leadToken={leadToken}/>
            <StyledDivider/>
            <FrameColors leadToken={leadToken}/>
            <StyledDivider/>
            <Grid container xs={12}>
                <Quiz
                    leadToken={leadToken}
                    currentStep={currentQuizStep}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                    setBackOverride={setBackOverride}
                />
            </Grid>
        </Grid>
    );
};

const Landing = () : JSX.Element => {
    const {leadToken} = useLeadTokenContext();

    return leadToken ? <LandingDisplay leadToken={leadToken}/> : <LinearProgress/>;
};

export default Landing;
