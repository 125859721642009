import Brightness5Icon from '@mui/icons-material/Brightness5';
import HandymanIcon from '@mui/icons-material/Handyman';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import {Box, Stack, useTheme} from '@mui/material';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import WindowCornerImage from 'src/assets/window-corner.png';

const StyledWhyHeader = styled(Typography)(() => ({color: '#6CC14C', fontSize: '22px', fontWeight: 500}));

const WindowMaterials = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'window_materials',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'window_materials'});
        }
    }, [isSectionVisible]);

    return <>
        <Grid xs={12} sx={{textAlign: 'center'}}>
            <Typography variant={'h3'} sx={{
                mt: 5, px: 2,
                fontSize: {
                    xs: "clamp(1.25rem, 2.5vw, 1.25rem)",
                    sm: "clamp(1.75rem, 2.5vw, 1.75rem)",
                    md: "clamp(2.25rem, 2.5vw, 2.25rem)",
                    lg: "clamp(3rem, 2.5vw, 3rem)",
                },
            }} ref={ref}>
            See The Difference Fibrex Material Makes!
            </Typography>
        </Grid>
        <Grid xs={12} md={6} sx={{textAlign: 'center'}} pb={5}>
            <Stack sx={{mt: 5}}>
                <Grid container>
                    <Grid xs={2} xsOffset={2}><Brightness5Icon sx={{fontSize: isMobile ? '32px' : '48px'}}/></Grid>
                    <Grid xs={6} sx={{textAlign: 'left'}}>
                        <StyledWhyHeader>
                            Smart Materials
                        </StyledWhyHeader>
                        <Typography>
                        Fibrex material retains its stability and rigidity in all climates. Its strength
                        means thinner frames, more glass area, and <b>more natural light</b>.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{mt: 7}}>
                    <Grid xs={2} xsOffset={2}><ThermostatIcon sx={{fontSize: isMobile ? '32px' : '48px'}}/></Grid>
                    <Grid xs={6} sx={{textAlign: 'left'}}>
                        <StyledWhyHeader>Exceptional Performance</StyledWhyHeader>
                        <Typography>
                            Fibrex material blocks thermal transfer and seals stay weathertight to help <b>reduce
                            heating and cooling bills</b>.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{mt: 7}}>
                    <Grid xs={2} xsOffset={2}><HandymanIcon sx={{fontSize: isMobile ? '32px' : '48px'}}/></Grid>
                    <Grid xs={6} sx={{textAlign: 'left'}}>
                        <StyledWhyHeader>Outstanding Durability</StyledWhyHeader>
                        <Typography>
                            Fibrex material resists rot, decay, and fungal growth and <b>
                            requires little maintenance</b><sup>1</sup>.
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
        </Grid>
        <Grid xs={12} md={6} sx={{my: isMobile ? 0 : 5}}>
            <Box
                component={'img'}
                alt="Renewal by Anderson"
                src={WindowCornerImage}
                width={'100%'}
                sx={{px: isMobile ? 2 : 10, pb: isMobile ? 2 : 0}}
            />
        </Grid>
    </>;
};

export default WindowMaterials;
