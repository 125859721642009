import {Box, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import DiscoverCarousel from '@/components/DiscoverCarousel';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import RBAAcclaim from 'src/assets/rba-acclaim.png';
import RBAEnsemble from 'src/assets/rba-ensemble.png';
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

const DiscoverBeauty = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const eventMutation = useCreateLeadTokenEventMutation();

    const discoverBeautyRef = useRef<HTMLElement>(null);
    const isDiscoverBeautyVisible = useOnScreen(discoverBeautyRef);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        if (isDiscoverBeautyVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'discover_beauty',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'discover_beauty'});
        }
    }, [isDiscoverBeautyVisible]);

    return (
        <Stack
            sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                backgroundColor: '#f8f9fa',
            }}
        >
            <Typography variant={'h3'} sx={{
                mt: isMobile ? 2 : 5,
                p: isMobile ? 3 : 5,
                fontSize: {
                    xs: "clamp(1.4rem, 2.5vw, 1.4rem)",
                    sm: "clamp(1.75rem, 2.5vw, 1.75rem)",
                    md: "clamp(2.25rem, 2.5vw, 2.25rem)",
                    lg: "clamp(3rem, 2.5vw, 3rem)",
                },
            }} ref={discoverBeautyRef}>
                Discover Beauty That Fits Your Budget
            </Typography>
            <Grid container>
            <Grid xs={6} md={6} sx={{textAlign: 'center', mb: 2}}>
                <Box
                    component={'img'}
                    alt="Ensemble Entry Doors"
                    src={RBAEnsemble}
                    width={'50%'}
                />
            </Grid>
            <Grid xs={6} md={6} sx={{textAlign: 'center', mb: 2}}>
                <Box
                    component={'img'}
                    alt="Ensemble Entry Doors"
                    src={RBAAcclaim}
                    width={'50%'}
                />
            </Grid>
            </Grid>
            <DiscoverCarousel/>
        </Stack>
    );
};

export default DiscoverBeauty;
