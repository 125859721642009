import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Divider, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {RhfTextField} from 'mui-rhf-integration';
import type {Dispatch, SetStateAction} from 'react';
import TagManager from 'react-gtm-module';
import {useForm} from 'react-hook-form';
import Markdown from 'react-markdown';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {z} from 'zod';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import QuizNavigation from '@/components/QuizNavigation';
import {useAddRequesterMutation} from '@/mutations/lead-token';
import {useLapQuery} from '@/queries/lap';
import {useLsrcPhoneNumberQuery} from '@/queries/lsrc';
import {useOfferQuery} from '@/queries/offer';
import type {LeadToken} from '@/types/lead-token';
import {errorMap, phoneNumberSchema} from '@/utils/zod';

type Props = {
    leadToken : LeadToken;
    setCurrentStepIndex : Dispatch<SetStateAction<number>>;
    setBackOverride : Dispatch<SetStateAction<boolean>>;
};

const schema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    phoneNumber: phoneNumberSchema,
    zip: z.string().min(1).nullable(),
});

type FormValues = z.infer<typeof schema>;

const QuizOfferStep = ({leadToken, setCurrentStepIndex, setBackOverride} : Props) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const lapQuery = useLapQuery(leadToken.pageId);
    const offerQuery = useOfferQuery(leadToken.pageId);
    const requesterMutation = useAddRequesterMutation(leadToken);
    const phoneQuery = useLsrcPhoneNumberQuery(leadToken.sourceId);

    let phoneNumber = null;

    if (phoneQuery.data) {
        phoneNumber = phoneQuery.data;
    }

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: {
            firstName: leadToken.requester?.firstName ?? undefined,
            lastName: leadToken.requester?.lastName ?? undefined,
            email: leadToken.requester?.email ?? undefined,
            phoneNumber: leadToken.requester?.phoneNumber ?? undefined,
            zip: leadToken.zip,
        },
    });

    const handleSubmit = async (values : FormValues) => {
        await requesterMutation.mutateAsync(values, {
            onSuccess: () => {
                TagManager.dataLayer({dataLayer: {event: 'conversion'}});
                navigate({
                    pathname: 'confirmation',
                    search: `?${createSearchParams(
                        Object.fromEntries(searchParams)
                    ).toString()}`,
                });
            },
        });
    };

    return <Grid
        container
        xs={12}
        component={'form'}
        name={'quiz_step_offer'}
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
    >
        <Grid xs={12} sx={{m: isMobile ? 0 : 5}}>
            <Box sx={{
                p: 2,
                backgroundColor: '#a1db80',
                border: '1px solid #000',
                borderRadius: 4,
                textAlign: 'center',
                mb: 2,
            }}>
                <Typography variant={'h4'} sx={{fontWeight: 400}}>
                    Claim your local offer
                </Typography>
                <Divider sx={{py: 1}}/>
                <Box>
                    {lapQuery.data
                        ? <Typography
                            variant={'h5'}
                            className='dangerous'
                            dangerouslySetInnerHTML={{__html: offerQuery.data?.offerText ?? ''}}
                        />
                        : <></>}
                </Box>
                <Box textAlign="center">
                    <Markdown>{offerQuery.data?.offerExpiration}</Markdown>
                </Box>
            </Box>
        </Grid>
        <Grid xs={12}>
            <Grid container spacing={2}>
                <Grid xs={12} md={6}>
                    <RhfTextField
                        control={form.control}
                        name={'firstName'}
                        label={'First Name'}
                        fullWidth
                        required
                        helperText={' '}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <RhfTextField
                        control={form.control}
                        name={'lastName'}
                        label={'Last Name'}
                        fullWidth
                        required
                        helperText={' '}
                    />
                </Grid>
                <Grid xs={12}>
                    <RhfTextField
                        control={form.control}
                        name={'email'}
                        label={'Email'}
                        fullWidth
                        required
                        helperText={' '}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <RhfTextField
                        control={form.control}
                        label="Phone Number"
                        name="phoneNumber"
                        required
                        InputProps={{
                            inputComponent: PhoneNumberInput,
                        }}
                        sx={{flexBasis: '50%'}}
                        autoComplete="tel"
                        fullWidth
                        helperText={' '}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <RhfTextField
                        control={form.control}
                        name={'zip'}
                        label={'Zip Code'}
                        fullWidth
                        required
                        helperText={''}
                        disabled
                    />
                </Grid>
                <Grid xs={12}>
                    <Typography sx={{fontSize: '12pt', fontWeight: 'bold'}}>
                        By submitting this inquiry, you consent to receive calls and texts from an
                        auto-phone system at the number you provided above to hear more about our
                        sales and special events. Consent is not required for purchase, you can instead
                        call {phoneNumber} for a free estimate.  Revocation of consent can be made
                        to privacy@andersencorp.com or for texts by replying STOP to any message.
                        Messaging data rates may apply.  You have reviewed and agree to this
                        page’s <HashLink to={{
                            pathname: '/privacy',
                            search: `?${createSearchParams(
                                Object.fromEntries(searchParams)
                            ).toString()}`,
                            hash: '#dispute-resolution-policy',
                        }}>
                        Dispute Resolution Policy</HashLink> and <HashLink
                            to={{pathname: '/privacy',
                                search: `?${createSearchParams(
                                    Object.fromEntries(searchParams)
                                ).toString()}`,
                                hash: '#privacy-policy'}}>Privacy Policy</HashLink>.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <QuizNavigation
            buttonText={'Submit Your Quiz'}
            disabled={!form.formState.isValid}
            stepNumber={5}
            setCurrentStepIndex={setCurrentStepIndex}
            setBackOverride={setBackOverride}
        />
    </Grid>;
};

export default QuizOfferStep;
