import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Box, Paper, useTheme} from '@mui/material';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef, useState} from 'react';
import TagManager from 'react-gtm-module';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import Awning1 from 'src/assets/windows-and-doors/awning-1.jpg';
import Awning2 from 'src/assets/windows-and-doors/awning-2.jpg';
import Awning3 from 'src/assets/windows-and-doors/awning-3.jpg';
import Bay1 from 'src/assets/windows-and-doors/bay-1.jpg';
import Bay2 from 'src/assets/windows-and-doors/bay-2.jpg';
import Bay3 from 'src/assets/windows-and-doors/bay-3.jpg';
import Casement1 from 'src/assets/windows-and-doors/casement-1.jpg';
import Casement2 from 'src/assets/windows-and-doors/casement-2.jpg';
import Casement3 from 'src/assets/windows-and-doors/casement-3.jpg';
import DoubleHung1 from 'src/assets/windows-and-doors/double-hung-1.jpg';
import DoubleHung2 from 'src/assets/windows-and-doors/double-hung-2.jpg';
import DoubleHung3 from 'src/assets/windows-and-doors/double-hung-3.jpg';
import Entry1 from 'src/assets/windows-and-doors/entry-1.jpg';
import Entry2 from 'src/assets/windows-and-doors/entry-2.jpg';
import Entry3 from 'src/assets/windows-and-doors/entry-3.jpg';
import Patio1 from 'src/assets/windows-and-doors/patio-1.jpg';
import Patio2 from 'src/assets/windows-and-doors/patio-2.jpg';
import Patio3 from 'src/assets/windows-and-doors/patio-3.jpg';
import AwningThumbnail from 'src/assets/windows-and-doors/productcard-awning.png';
import CasementThumbnail from 'src/assets/windows-and-doors/productcard-casement.png';
import PatioThumbnail from 'src/assets/windows-and-doors/productcard-contemporarysliding-300x288.webp';
import DoubleHungThumbnail from 'src/assets/windows-and-doors/productcard-doublehung.png';
import EntryThumbnail from 'src/assets/windows-and-doors/productcard-ensembleentry.jpeg';
import PictureThumbnail from 'src/assets/windows-and-doors/productcard-picture.png';
import SlidingThumbnail from 'src/assets/windows-and-doors/productcard-sliding.png';
import SpecialtyThumbnail from 'src/assets/windows-and-doors/productcard-specialty.png';
import Sliding1 from 'src/assets/windows-and-doors/sliding-1.png';
import Sliding2 from 'src/assets/windows-and-doors/sliding-2.png';
import Sliding3 from 'src/assets/windows-and-doors/sliding-3.jpg';
import Specialty1 from 'src/assets/windows-and-doors/specialty-1.jpg';
import Specialty2 from 'src/assets/windows-and-doors/specialty-2.jpg';
import Specialty3 from 'src/assets/windows-and-doors/specialty-3.jpg';

const optionsArray = [
    {
        headline: 'Double-Hung Windows',
        subtext: 'Double-hung windows offer a traditional look with a wide range of design options.',
        thumbnail: DoubleHungThumbnail,
        images: [DoubleHung1, DoubleHung2, DoubleHung3],
        type: 'window',
    },
    {
        headline: 'Casement Windows',
        subtext: 'Casement windows are our most energy-efficient, ventilating window style.',
        thumbnail: CasementThumbnail,
        images: [Casement1, Casement2, Casement3],
        type: 'window',
    },
    {
        headline: 'Picture & Bay Windows',
        subtext: 'Our picture and combination windows are the ideal solution for showcasing a beautiful view.',
        thumbnail: PictureThumbnail,
        images: [Bay1, Bay2, Bay3],
        type: 'window',
    },
    {
        headline: 'Sliding Windows',
        subtext: 'Maximize your view with contemporary Renewal by Andersen® sliding windows.',
        thumbnail: SlidingThumbnail,
        images: [Sliding1, Sliding2, Sliding3],
        type: 'window',
    },
    {
        headline: 'Specialty Windows',
        subtext: 'Specialty window styles include round, arch, circle, circle top, pentagon, arched and more.',
        thumbnail: SpecialtyThumbnail,
        images: [Specialty1, Specialty2, Specialty3],
        type: 'window',
    },
    {
        headline: 'Awning Windows',
        subtext: 'Replacement awning windows are easy to open, great for hard-to-reach areas.',
        thumbnail: AwningThumbnail,
        images: [Awning1, Awning2, Awning3],
        type: 'window',
    },
    {
        headline: 'Andersen® Patio Doors',
        subtext: 'We offer a variety of Andersen® patio doors, each delivering the high standard'
            + 'of operational performance and durability.',
        thumbnail: PatioThumbnail,
        images: [Patio1, Patio2, Patio3],
        type: 'door',
    },
    {
        headline: 'Ensemble™ Entry Doors',
        subtext: 'Choose from a variety of entry door styles that offer beauty, durability, and security to enhance '
            + 'your home’s entryway.',
        thumbnail: EntryThumbnail,
        images: [Entry1, Entry2, Entry3],
        type: 'door',
    },
];

const StyledOptionBox = styled(Box)(() => ({
    border: '2px solid #50565a',
    width: '11rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: '15px',
    marginLeft: '5px',
}));

type OptionsProps = {option : {headline : string; subtext : string; thumbnail : string; images : string[]}};

const OptionExamplesBox = ({option} : OptionsProps) : React.ReactElement => {
    return <Paper sx={{mt: 2, mx: 10}}>
        <Grid container xs={12}>
            <Grid xs={12} sx={{textAlign: 'center'}}>
                <Typography variant={'h4'} sx={{
                    mt: 3,
                    fontSize: {
                        xs: "clamp(1.5rem, 2.5vw, 1.5rem)",
                        sm: "clamp(1.75rem, 2.5vw, 1.75rem)",
                        md: "clamp(2.25rem, 2.5vw, 2.25rem)",
                        lg: "clamp(3rem, 2.5vw, 3rem)",
                    },
                }}>{option.headline}</Typography>
            </Grid>
            <Grid xs={12} sx={{textAlign: 'center'}}>
                <Typography variant={'body1'} sx={{my: 2, px: 2}}>{option.subtext}</Typography>
            </Grid>
            <Grid container xs={12} sx={{textAlign: 'center', justifyContent: 'center'}}>
                {option.images.map(
                    (image, index) => <Grid
                        md={12}
                        lg={4}
                        key={index}
                        sx={{px: 2, mb: 2}}
                    >
                        <Box
                            component={'img'}
                            alt="Renewal by Anderson"
                            src={image}
                            sx={{width: '100%'}}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    </Paper>;
};

const WindowDoorOptions = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [selectedOption, setSelectedOption] = useState(0);
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'window_door_options',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'window_door_options'});
        }
    }, [isSectionVisible]);

    const tabRefs = useRef<Array<HTMLDivElement | null>>([]);

    return <Grid container xs={12} sx={{justifyContent: 'center', backgroundColor: '#fbfaf7', py: 5}}>
        <Grid xs={12} textAlign={'center'}>
            <Typography variant={'h3'} ref={ref} sx={{
                fontSize: {
                    xs: "clamp(1.4rem, 2.5vw, 1.4rem)",
                    sm: "clamp(1.75rem, 2.5vw, 1.75rem)",
                    md: "clamp(2.25rem, 2.5vw, 2.25rem)",
                    lg: "clamp(3rem, 2.5vw, 3rem)",
                },
            }}>
                Explore All the Window and Door Possibilities!
            </Typography>
        </Grid>
        <Grid xs={12} sx={{textAlign: 'center', maxWidth: '850px', my: 3, px: 2}}>
            <Typography variant={'body1'}>
                Every window and door we offer is custom built. We offer a wide range of styles,
                colors, hardware and grille options.  Find the perfect match to customize and complete your home’s
                one-of-a-kind design.
            </Typography>
        </Grid>
        <Grid xs={12} sx={{
            mx: isMobile ? 2 : 10,
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            <ArrowBackIosIcon
                onClick={() => {
                    setSelectedOption(selectedOption > 0 ? selectedOption - 1 : 0);
                    tabRefs.current[selectedOption]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'center',
                    });
                }}
                sx={{position: 'relative', top: '50%', transform: 'translateY(-50%)', ':hover': {cursor: 'pointer'}}}
            />
            <Grid xs={12} sx={{display: 'flex', justifyContent: 'space-between', overflow: 'scroll'}}>
                {optionsArray.map((option, index) => <StyledOptionBox
                    key={index}
                    ref={(el : HTMLDivElement) => tabRefs.current[index] = el}
                    sx={{
                        px: 5,
                        backgroundColor: index === selectedOption ? '#a1db80' : '#fff',
                    }}
                    onClick={() => {
                        setSelectedOption(index);
                        tabRefs.current[selectedOption]?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'center',
                        });
                    }}>
                    <Box
                        component={'img'}
                        alt="Renewal by Anderson"
                        src={option.thumbnail}
                        height={option.type === 'door' ? '50px' : '30px'}
                    />
                    <Typography sx={{fontSize: '14px', mt: 1, lineHeight: 1}}>{option.headline}</Typography>
                </StyledOptionBox>)}
            </Grid>
            <ArrowForwardIosIcon
                onClick={() => {
                    setSelectedOption(selectedOption < 7 ? selectedOption + 1 : 7);
                    tabRefs.current[selectedOption]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'center',
                    });
                }}
                sx={{position: 'relative', top: '50%', transform: 'translateY(-50%)', ':hover': {cursor: 'pointer'}}}
            />
        </Grid>
        <Grid container xs={12} justifyContent={'center'}>
            <OptionExamplesBox
                option={optionsArray[selectedOption]}
            />
        </Grid>
    </Grid>;
};

export default WindowDoorOptions;
